
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 window.Vue = require('vue');

 import'./bulma';

 import'./script';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//  var SocialSharing = require('vue-social-sharing');
//  Vue.use(SocialSharing);
/*-----------------General Components ---------------*/
import Slick from 'vue-slick';
import SlickAnimation from './components/GeneralComponents/slickCarouselAnimation';
import VueLazyload from 'vue-lazyload';
import VueTranslate from 'vue-translate-plugin';
import SlotForm from './components/GeneralComponents/SlotForm';
import VeeValidate from 'vee-validate';
import Uploader from './components/GeneralComponents/Uploader';
import VueSweetalert2 from 'vue-sweetalert2';


/*-------------Site Components---------------*/
import Counter from './components/SiteComponents/Counter';
import StickyHeader from './components/SiteComponents/StickyHeader';
import Team from './components/SiteComponents/Team';
import Services from './components/SiteComponents/Services';
import Blog from './components/SiteComponents/Blog';
import Products from './components/SiteComponents/Products';


/*-------------USES---------------*/

Vue.use(VeeValidate);
Vue.use(VueTranslate);
Vue.use(VueSweetalert2);

/*----------- Lazyload ------------*/
Vue.use(VueLazyload, {
	preLoad: 100,
	error: '/front/img/illustrations/no_pictures.svg',
	loading: '/front/img/illustrations/loader.svg',
	attempt: 1,
	adapter: {
		loading (listender, Init) {
			listender.el.style.height = "4rem";
		},
		loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
			el.style.height= "";
		},
	}
});
/*-----------Global Variables ----------*/
 import translate from './components/SiteComponents/translation/general.json';
 Vue.mixin({
 	data: function() {
 		return {
 			translate:translate
 		}
 	},
 	locales:translate,
 })

export {
	Slick,
	SlickAnimation,
	Counter,
	StickyHeader,
	SlotForm,
	Team,
	Services,
	Blog,
	Products,
	Uploader
}
const app = new Vue({
	el: '#app',
	components: {
		Slick,
		SlickAnimation,
		Counter,
		StickyHeader,
		SlotForm,
		Team,
		Services,
		Blog,
		Products,
		Uploader
	}
});
